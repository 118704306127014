<template>
  <v-container class="movieDetails align-start justify-center">
    <title>{{ movieDetails.title }}</title>
    <v-row justify="center" align="center" style="height: 7vw" />
    <v-row justify="center">
      <!-- TODO: Get this container to justify-center properly without fill-height -->
      <v-row justify="center" align="center">
        <v-col xs="6" sm="6" md="6" lg="6" xl="6">
          <v-img
            class="mx-auto"
            contain
            max-width="50%"
            :src="movieDetails.resultPhrases[0].poster"
          ></v-img>
        </v-col>
        <v-col cols="11">
          <h1>
            <em style="word-break: break-word">{{ movieDetails.title }}</em>
          </h1>
          <div>
            <h2 v-if="movieDetails.episodeTitle">
              "{{ movieDetails.episodeTitle }}" (S{{
                movieDetails.season.padStart(2, "0")
              }}
              E{{ movieDetails.episodeNumber.padStart(2, "0") }})
            </h2>
            <br />
            <p>Release Date: {{ movieDetails.releaseDate }}</p>
            <p>Actors: {{ movieDetails.actors}}</p>
            <p>Director: {{ movieDetails.director }}</p>
            <p>Genres: {{ movieDetails.genre }}</p>
            <p>
              Original Language:
              {{ movieDetails.originalLanguage.split(",")[0] }}
            </p>
          </div>
          <!-- <a
              style="width: 100px; display: block; margin-left: 16px"
              :href="amazonHref"
              target="_blank"
            >
              <v-img
                contain
                max-width="100px"
                src="../assets/amazon-button1.png"
            /></a> -->
        </v-col>
      </v-row>

      <v-row
        style="
          max-width: 890px;
          margin-top: 5%;
          margin-left: 5px;
          margin-right: 5px;
        "
      >
        <v-expansion-panels multiple>
          <v-expansion-panel
            style="margin-bottom: 10px; font-size: 1.2em"
            v-for="(phrase, index) in movieDetails.phrase"
            :key="index"
          >
            <v-expansion-panel-header color="primary">
              <div v-html="getPhraseAndText(phrase[4])" />
            </v-expansion-panel-header>
            <v-expansion-panel-content style="background-color: #fcfbc9">
              <div class="phraseText" v-html="getPhrasesAndText(phrase)" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
      <br />
    </v-row>

    <v-row align="center" justify="center" style="padding-top: 38px">
      <br />
      <!-- <banner-ad :ads="ads" /> -->
      <InFeedAdsense
        data-ad-client="ca-pub-1497018985243834"
        data-ad-slot="6409307439"
        data-ad-format="fluid"
        data-ad-layout="in-article"
        style="min-width: 300px; min-height: 100%"
      >
      </InFeedAdsense>
      <!-- <a target="_blank" :href="ad.href">
        <img
          v-if="!isMobile"
          style="max-height: 200px"
          :src="ad.imgSrc"
          border="0"
          :alt="ad.alt"
        />
        <img v-if="isMobile" :src="ad.imgSrc" border="0" :alt="ad.alt" />
      </a> -->
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
// import BannerAd from "@/components/BannerAd.vue";
import { AmazonAffiliateAds } from "../assets/affiliateLinks";

export default {
  name: "SearchResults",
  data: () => ({
    ads: [],
  }),
  components: {
    // BannerAd,
  },
  methods: {
    getPhraseAndText(phrase) {
      return phrase ? `${phrase.start} : ${phrase.text}` : "";
    },
    getPhrasesAndText(phrases) {
      let string = ``;
      for (const [i, p] of phrases.entries()) {
        if (!p) continue;
        if (i === 4) {
          string = string.concat(`<b>${p.start} : ${p.text}</b>\n`);
          continue;
        }
        string = string.concat(`${p.start} : ${p.text}\n`);
      }
      return string;
    },
  },
  computed: {
    //Broken affiliate stuff: &i=movies-tv&ref=nb_sb_noss_2&tracking_id=popmysticid-20?tag=popmysticid-20&camp=1789&linkCode=ll2&linkId=6dc6b945fc75be95178c99030c16e79c
    amazonHref() {
      return `https://www.amazon.com/s?k=${this.movieDetails.title}`;
    },
    ...mapState(["movieDetails"]),
  },
  created() {
    const arr = [...AmazonAffiliateAds];
    let j, x, i;
    for (i = arr.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = arr[i];
      arr[i] = arr[j];
      arr[j] = x;
    }
    this.ads = arr;
  },
};
</script>

<style scoped>
p {
  font-size: 150%;
  text-align: center;
  color: white;
  margin-bottom: 0;
}
h1,
h2 {
  color: white;
  text-align: center;
}
em {
  text-align: center;
}
.phraseText {
  display: inline-block;
  margin-top: 1.25%;
  white-space: pre-wrap;
  font-size: 1.2rem;
}
.v-expansion-panel-header {
  font-size: 1.2rem !important;
}
</style>
